
export function toBase64(file:File){
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    })
}

export function formatearFecha(date: Date){
    const formato = new Intl.DateTimeFormat('en', {
        year:'numeric',
        month: '2-digit',
        day:'2-digit'
    })

    const [
        {value: month},,
        {value: day},,
        {value: year}
    ] = formato.formatToParts(date);

    return `${year}-${month}-${day}`;
}

export function formatearFechaCompleta(date: Date){

    date = new Date(date);

    const formato = new Intl.DateTimeFormat('en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });

    const[
        {value: month},,
        {value: day},,
        {value: year},,
        {value: hour},,
        {value: minute},,
        {value: second}
    ] = formato.formatToParts(date);

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function isMobile(){
    return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
    );
}

export function formatearFechaCompletaPIPE(date: Date){

  date = new Date(date);

  const formato = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
  });

  const[
      {value: month},,
      {value: day},,
      {value: year},,
      {value: hour},,
      {value: minute},,
      {value: second}
  ] = formato.formatToParts(date);

  return `${year}|${month}|${day}|${hour}|${minute}|${second}`;
}

export function mostrarBotonMenu(rol:number, titulo:string, rolUsuario: string, cli: string):boolean{
    
    if(cli.toUpperCase() != 'SHOPOLOGY' && (titulo.toUpperCase().indexOf('EVIDENCIA') >= 0 || titulo.toUpperCase().indexOf('INMEDIATA') >= 0 || rol == 3 )){
        return false;
      }
  
      if(rolUsuario == 'Admin' && rol <= 4){
        return true;
      }

      if(rolUsuario == 'Habit' && rol == 3){
        return true;
      }
  
      if(rolUsuario == 'Shopology' && rol <= 3){
        return true;
      }
  
      if(rolUsuario == 'Soporte' && rol <= 1){
        return true;
      }
  
      if(rolUsuario == 'Analista' && rol <= 0){
        return true;
      }
  
      if(rolUsuario == 'Externo' && rol <= 0){
        return true;
      }
  
      return false;
    
}


export function trimGeneral (s: string, c: string) {
  if (c === "]") c = "\\]";
  if (c === "^") c = "\\^";
  if (c === "\\") c = "\\\\";
  return s.replace(new RegExp(
    "^[" + c + "]+|[" + c + "]+$", "g"
  ), "");
}
