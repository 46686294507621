import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Instruccion } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class InstruccionService {
  
  private controlador = computed(() => 'instruccion');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos/${idEmpresa}`);
  }

  public obtenerActivos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idPolitica:number, idInstruccion: number): Observable<Instruccion>{
    return from(this.api.get<Instruccion>(`${idPolitica}/${idInstruccion}`));
    // return this.http.get<instruccionDTO>(`${this.apiURL}/${idPolitica}/${idInstruccion}`);
  }

  public crear(registro:Instruccion){
    return from(this.api.post<Instruccion, boolean>(``, registro));
    // return this.http.post(this.apiURL, registro);
  }

  public validarNombre(registro:Instruccion){
    return from(this.api.post<Instruccion, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:Instruccion){
    return from(this.api.put<Instruccion, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}
