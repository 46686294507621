
import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { AccionDecision, BasicObjectSMI } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class AccionDecisionService {

  private controlador = computed(() => 'acciondecision');
  private consumirApi = new ConsumirApi(this.controlador())

  constructor() {}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.consumirApi.get<BasicObjectSMI[]>(`todos/${idEmpresa}`));
  }

  public obtenerActivos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.consumirApi.get<BasicObjectSMI[]>(`${idEmpresa}`));
    //return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idDecision:number, idAccion: number): Observable<AccionDecision>{
    return from(this.consumirApi.get<AccionDecision>(`VersionMejorada/${idDecision}/${idAccion}`));
    //return this.http.get<accionDecisionDTO>(`${this.apiURL}/VersionMejorada/${idDecision}/${idAccion}`);
  }

  public crear(registro:AccionDecision){
    return from(this.consumirApi.post<AccionDecision,boolean>(`VersionMejorada`,registro));
    // return this.http.post(`${this.apiURL}/VersionMejorada`, registro);
  }

  public validarNombre(registro:AccionDecision){
    return from(this.consumirApi.post<AccionDecision,boolean>(`VersionMejorada/validarNombre`,registro));
    //return this.http.post(`${this.apiURL}/VersionMejorada/validarNombre`, registro);
  }

  public editar(id: number, registro:AccionDecision){
    return from(this.consumirApi.put<AccionDecision,boolean>(`VersionMejorada/${id}`,registro));
    //return this.http.put(`${this.apiURL}/VersionMejorada/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.consumirApi.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}
