import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class UtilidadesService {

  private controlador = computed(() => 'utilidades');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerIconos():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`iconos`));
    // return this.http.get<coleccionDTO[]>(this.apiURL + '/iconos');
  }

  public obtenerLogros():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`logrosSMI`));
    // return this.http.get<coleccionDTO[]>(this.apiURL + '/logrosSMI');
  }

  public obtenerEstadosAnimo():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`estadosAnimo`));
    // return this.http.get<coleccionDTO[]>(this.apiURL + '/logrosSMI');
  }
}
