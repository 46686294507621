import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Elemento } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class ElementoService {

  private controlador = computed(() => 'elemento');
  private consumirApi = new ConsumirApi(this.controlador())

  constructor() {}

  public obtenerTodos():Observable<BasicObjectSMI[]>{
    return from(this.consumirApi.get<BasicObjectSMI[]>(`todos`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos`);
  }

  public obtenerUnidades():Observable<BasicObjectSMI[]>{
    return from(this.consumirApi.get<BasicObjectSMI[]>(`unidades`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/unidades`);
  }

  public obtenerMonedas():Observable<BasicObjectSMI[]>{
    return from(this.consumirApi.get<BasicObjectSMI[]>(`monedas`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/monedas`);
  }

  public obtenerPorId(id: number): Observable<Elemento>{
    return from(this.consumirApi.get<Elemento>(`${id}`));
    // return this.http.get<elementoDTO>(`${this.apiURL}/${id}`);
  }

  public crear(elemento:Elemento){
    return from(this.consumirApi.post<Elemento, boolean>(``, elemento));
    // return this.http.post(this.apiURL,elemento);
  }

  public validarNombre(elemento:Elemento){
    return from(this.consumirApi.post<Elemento, boolean>(`validarNombre`, elemento));
    // return this.http.post(`${this.apiURL}/validarNombre`, elemento);
  }

  public editar(id: number, elemento:Elemento){
    return from(this.consumirApi.put<Elemento, boolean>(`${id}`, elemento));
    // return this.http.put(`${this.apiURL}/${id}`, elemento);
  }

  public borrar(id:number){
    return from(this.consumirApi.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}
