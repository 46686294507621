import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, DecisionTarima } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class DecisionTarimaService {

  private controlador = computed(() => 'decisiontarima');
  private api = new ConsumirApi(this.controlador());

  constructor() {}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos/${idEmpresa}`);
  }

  public obtenerActivos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, idDecision: number): Observable<DecisionTarima>{
    return from(this.api.get<DecisionTarima>(`VersionMejorada/${idEmpresa}/${idDecision}`));
    // return this.http.get<decisionTarimaDTO>(`${this.apiURL}/VersionMejorada/${idEmpresa}/${idDecision}`);
  }

  public crear(registro:DecisionTarima){
    return from(this.api.post<DecisionTarima, boolean>(`VersionMejorada`, registro));
    // return this.http.post(`${this.apiURL}/VersionMejorada`, registro);
  }

  public validarNombre(registro:DecisionTarima){
    return from(this.api.post<DecisionTarima, boolean>(`VersionMejorada/validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/VersionMejorada/validarNombre`, registro);
  }

  public editar(id: number, registro:DecisionTarima){
    return from(this.api.put<DecisionTarima, boolean>(`VersionMejorada/${id}`, registro));
    // return this.http.put(`${this.apiURL}/VersionMejorada/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}
