
import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, CriterioReporte, Evidencia, EvidenciaItem } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class EvidenciaService {

  private controlador = computed(() => 'evidencia');
  private consumirApi = new ConsumirApi(this.controlador())

  constructor() {}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{

    return from(this.consumirApi.get<BasicObjectSMI[]>(`todos/${idEmpresa}`));

  }

  public obtenerActivos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.consumirApi.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, idsupervisor: number): Observable<Evidencia>{
    return from(this.consumirApi.get<Evidencia>(`${idEmpresa}/${idsupervisor}`));
    // return this.http.get<evidenciaDTO>(`${this.apiURL}/${idEmpresa}/${idsupervisor}`);
  }

  public crear(registro:Evidencia){
    return from(this.consumirApi.post<Evidencia,boolean>(``, registro));
    // return this.http.post(this.apiURL, registro);
  }

  public validarNombre(registro:Evidencia){
    return from(this.consumirApi.post<Evidencia, boolean>(`validarEvidencia`, registro));
    // return this.http.post(`${this.apiURL}/validarEvidencia`, registro);
  }

  public editar(id: number, registro:Evidencia){
    return from(this.consumirApi.put<Evidencia, boolean>(`${id}`,registro ));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.consumirApi.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }

  public obtenerListadoEvidenciasSimple(objeto:CriterioReporte){
    return from(this.consumirApi.post<CriterioReporte, EvidenciaItem[]>(`ObtenerListadoEvidenciasSimple`,objeto));
    // return this.http.post<evidenciaListadoDTO[]>(`${this.apiURL}/ObtenerListadoEvidenciasSimple`, objeto);
  }

  public generarReporteEvidencia(id:number){
    return from(this.consumirApi.get<any>(`GenerarReporteEvidencia/${id}`));
    // return this.http.get(`${this.apiURL}/GenerarReporteEvidencia/${id}`,{ responseType: 'blob' });
  }

  public asignarUsuario(objeto:EvidenciaItem){
    return from(this.consumirApi.post<EvidenciaItem,any>(`AsignarUsuario}`, objeto));
    // return this.http.post(`${this.apiURL}/AsignarUsuario`, objeto);
  }
}
