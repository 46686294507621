import { Injectable, computed } from '@angular/core';
import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Estandar } from '@interfaces/index';

import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class EstandarService {

  private controlador = computed(() => 'estandarpolitica');
  private api = new ConsumirApi(this.controlador());

  constructor() {}

  public obtenerTodos():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(``));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}`);
  }

  public obtenerPorId(id: number): Observable<Estandar>{
    return from(this.api.get<Estandar>(`${id}`));
    // return this.http.get<estandarDTO>(`${this.apiURL}/${id}`);
  }

  public crear(registro:Estandar){
    return from(this.api.post<Estandar, boolean>(``, registro));
    // return this.http.post(this.apiURL, registro);
  }

  public validarNombre(registro:Estandar){
    return from(this.api.post<Estandar, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:Estandar){
    return from(this.api.put<Estandar, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}