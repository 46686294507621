import { Injectable, computed } from '@angular/core';
import { BasicObjectSMI, Entrevista } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntrevistaService {

  private controlador = computed(() => 'entrevista');
  private consumirApi = new ConsumirApi(this.controlador())

  constructor() {}

  public obtenerTodos(idLider:number):Observable<BasicObjectSMI[]>{
    return from(this.consumirApi.get<BasicObjectSMI[]>(`todos/${idLider}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos`);
  }

  public obtenerPorId(id: number): Observable<Entrevista>{
    return from(this.consumirApi.get<Entrevista>(`${id}`));
    // return this.http.get<elementoDTO>(`${this.apiURL}/${id}`);
  }

  public crear(entrevista:Entrevista){
    return from(this.consumirApi.post<Entrevista, boolean>(``, entrevista));
    // return this.http.post(this.apiURL,elemento);
  }

  public editar(entrevista:Entrevista){
    return from(this.consumirApi.put<Entrevista, boolean>(``, entrevista));
    // return this.http.put(`${this.apiURL}/${id}`, elemento);
  }

  public borrar(id:number){
    return from(this.consumirApi.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }

  public generarReporte(id:number){
    return from(this.consumirApi.getArchivo(`GenerarReporte/${id}`));
    // return this.http.get(`${this.apiURL}/GenerarReporteEvento/${id}`,{ responseType: 'blob' });
  }
}
