import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { ConsumirApi } from '@utils/index';
import { Contacto, BasicObjectSMI } from '@interfaces/index';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  private controlador = computed(() => 'contacto');
  private api = new ConsumirApi(this.controlador());

  constructor() {}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerContactosHabit(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`obtenerContactosHabit/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerContactoCorreo(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`contactoCorreo/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/contactoCorreo/${idEmpresa}`);
  }

  public obtenerCorreos(idEmpresa:number):Observable<Contacto[]>{
    return from(this.api.get<Contacto[]>(`correos/${idEmpresa}`));
    // return this.http.get<contactoDTO[]>(`${this.apiURL}/correos/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, idContacto: number): Observable<Contacto>{
    return from(this.api.get<Contacto>(`${idEmpresa}/${idContacto}`));
    // return this.http.get<contactoDTO>(`${this.apiURL}/${idEmpresa}/${idContacto}`);
  }

  public crear(registro:Contacto){
    return from(this.api.post<Contacto, boolean>(``, registro));
    // return this.http.post(this.apiURL,registro);
  }

  public validarNombre(registro:Contacto){
    return from(this.api.post<Contacto, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:Contacto){
    return from(this.api.put<Contacto, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }

  public obtenerCorreoContacto(id: number):Observable<string>{
    return from(this.api.getString(`obtenerCorreoContacto/${id}`))
  }
}
