import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Politica } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class PoliticaService {

  private controlador = computed(() => 'politica');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos/${idEmpresa}`);
  }

  public obtenerActivos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerDetallePreguntas(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`detalle/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/detalle/${idEmpresa}`);
  }

  public obtenerNormales(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`normales/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/normales/${idEmpresa}`);
  }

  public obtenerEventoExtraFallas(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`EventoExtraFallas/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/EventoExtraFallas/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, idPolitica: number): Observable<Politica>{
    return from(this.api.get<Politica>(`${idEmpresa}/${idPolitica}`));
    // return this.http.get<politicaDTO>(`${this.apiURL}/${idEmpresa}/${idPolitica}`);
  }

  public crear(registro:Politica){
    return from(this.api.post<Politica, boolean>(``,registro));
    // return this.http.post(this.apiURL, registro);
  }

  public validarNombre(registro:Politica){
    return from(this.api.post<Politica, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:Politica){
    return from(this.api.put<Politica, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}
