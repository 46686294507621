import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Recomendacion } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class RecomendacionService {

  private controlador = computed(() => 'recomendacion');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, idRecomendacion: number): Observable<Recomendacion>{
    return from(this.api.get<Recomendacion>(`${idEmpresa}/${idRecomendacion}`));
    // return this.http.get<recomendacionDTO>(`${this.apiURL}/${idEmpresa}/${idRecomendacion}`);
  }

  public crear(registro:Recomendacion){
    return from(this.api.post<Recomendacion, boolean>(``, registro));
    // return this.http.post(this.apiURL,registro);
  }

  public validarNombre(registro:Recomendacion){
    return from(this.api.post<Recomendacion, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:Recomendacion){
    return from(this.api.put<Recomendacion, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}
