import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, ProblemaGeneral } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class ProblemaGeneralService {

  private controlador = computed(() => 'problema');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos`);
  }

  public obtenerActivos():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(``));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}`);
  }

  public obtenerPorId(idProblema: number): Observable<ProblemaGeneral>{
    return from(this.api.get<ProblemaGeneral>(`${idProblema}`));
    // return this.http.get<problemaGeneralDTO>(`${this.apiURL}/${idProblema}`);
  }

  public crear(registro:ProblemaGeneral){
    return from(this.api.post<ProblemaGeneral, boolean>(``, registro));
    // return this.http.post(`${this.apiURL}`, registro);
  }

  public validarNombre(registro:ProblemaGeneral){
    return from(this.api.post<ProblemaGeneral, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:ProblemaGeneral){
    return from(this.api.put<ProblemaGeneral, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}