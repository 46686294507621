import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { ConsultaTCTopBottom, EmpresaInfo, GraficaEventosPorCliente, GraficaNumEventosUsuario, GraficaTopBottom } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class GraficaService {

  private controlador = computed(() => 'grafica');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  obtenerDatosGraficaUsuario(username:string):Observable<GraficaNumEventosUsuario>{

    return from(this.api.get<GraficaNumEventosUsuario>(`obtenerDatosGraficaUsuario/${username}`));

    // const obtenerDatosGraficaUsuario = async (username:string) => {

    //   let resp = await fetch(`${this.apiURL}/obtenerDatosGraficaUsuario/${username}`);

    //   let data = await resp.json() as graficaNumEventosUsuario;

    //   return data;
    // }

    // return from(obtenerDatosGraficaUsuario(username));
  }

  obtenerEventosGraficaPorCliente(username:string):Observable<GraficaEventosPorCliente>{

    return from(this.api.get<GraficaEventosPorCliente>(`ObtenerEventosPorCliente/${username}`));

    // const obtenerEventosGraficaPorCliente = async (username:string) => {

    //   let resp = await fetch(`${this.apiURL}/ObtenerEventosPorCliente/${username}`);

    //   let data = await resp.json() as graficaEventosPorCliente;

    //   return data;
    // }
    // return from(obtenerEventosGraficaPorCliente(username));
  }

  obtenerTCPorCliente(username:string):Observable<GraficaEventosPorCliente>{

    return from(this.api.get<GraficaEventosPorCliente>(`ObtenerTCPorCliente/${username}`));

    // const obtenerTCPorCliente = async (username:string) => {
    //   let resp = await fetch(`${this.apiURL}/ObtenerTCPorCliente/${username}`);

    //   let data = await resp.json() as graficaEventosPorCliente;

    //   return data;
    // }
    // return from(obtenerTCPorCliente(username));
  }

  obtenerTopBottom(objeto:ConsultaTCTopBottom){

    return from(this.api.post<ConsultaTCTopBottom, GraficaTopBottom>(`ObtenerTopBottom`, objeto));

    // const obtenerTopBottom = async (objeto:consultaTCTopBottom) => {

    //   let resp = await fetch(`${this.apiURL}/ObtenerTopBottom`, {
    //     method:'POST',
    //     headers: {'Content-Type': 'application/json'},
    //     body: JSON.stringify(objeto)
    //   });

    //   let data = await resp.json() as graficaTopBottom;

    //   return data;
    // }

    // return from(obtenerTopBottom(objeto));
  }

  obtenerInfoClientes(username:string):Observable<EmpresaInfo[]>{

    return from(this.api.get<EmpresaInfo[]>(`ObtenerInfoClientes/${username}`));

    // const obtenerInfoClientes = async (username:string) => {
    //   let resp = await fetch(`${this.apiURL}/ObtenerInfoClientes/${username}`);

    //   let data = await resp.json() as empresaInfo[];

    //   return data;
    // }

    // return from(obtenerInfoClientes(username));
  }

  obtenerInfoClientesSimple(username:string):Observable<EmpresaInfo[]>{

    return from(this.api.get<EmpresaInfo[]>(`ObtenerInfoClientesSimple/${username}`));

    // const obtenerInfoClientesSimple = async (username:string) => {
    //   let resp = await fetch(`${this.apiURL}/ObtenerInfoClientesSimple/${username}`);

    //   let data = await resp.json() as empresaInfo[];

    //   return data;
    // }

    // return from(obtenerInfoClientesSimple(username));
  }

  obtenerTopBottomExterno(objeto:ConsultaTCTopBottom){

    return from(this.api.post<ConsultaTCTopBottom, GraficaTopBottom>(`ObtenerTopBottomExterno`, objeto));

    // const obtenerTopBottomExterno = async (objeto:consultaTCTopBottom) => {
    //   let resp = await fetch(`${this.apiURL}/ObtenerTopBottomExterno`, {
    //     method:'POST',
    //     headers: {'Content-Type': 'application/json'},
    //     body: JSON.stringify(objeto)
    //   });

    //   let data = await resp.json() as graficaTopBottom;

    //   return data;
    // }

    // return from(obtenerTopBottomExterno(objeto));
  }
}
