import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { RolSMI } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  private controlador = computed(() => 'rol');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos():Observable<RolSMI[]>{
    return from(this.api.get<RolSMI[]>(``));
    // return this.http.get<rolSMIDTO[]>(this.apiURL);
  }

  public obtenerPorId(id: number): Observable<RolSMI>{
    return from(this.api.get<RolSMI>(`${id}`));
    // return this.http.get<rolSMIDTO>(`${this.apiURL}/${id}`);
  }

  public crear(rol:RolSMI){
    return from(this.api.post<RolSMI, boolean>(``, rol));
    // return this.http.post(this.apiURL,rol);
  }

  public validarNombre(rol:RolSMI){
    return from(this.api.post<RolSMI, boolean>(`validarNombre`, rol));
    // return this.http.post<boolean>(`${this.apiURL}/validarNombre`,rol);   
  }

  public editar(id: number, rol:RolSMI){
    return from(this.api.put<RolSMI, boolean>(`${id}`, rol));
    // return this.http.put(`${this.apiURL}/${id}`,rol);
  }

  public borrar(id: number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}
