import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, PreguntaBonus } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class PreguntaBonusService {

  private controlador = computed(() => 'preguntabonus');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idPoliticaBonus:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos/${idPoliticaBonus}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos/${idPoliticaBonus}`);
  }

  public obtenerActivos(idPoliticaBonus:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idPoliticaBonus}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idPoliticaBonus}`);
  }

  public obtenerPorId(idPoliticaBonus:number, id: number): Observable<PreguntaBonus>{
    return from(this.api.get<PreguntaBonus>(`${idPoliticaBonus}/${id}`));
    // return this.http.get<preguntaBonusDTO>(`${this.apiURL}/${idPoliticaBonus}/${id}`);
  }

  public crear(registro:PreguntaBonus){
    return from(this.api.post<PreguntaBonus, boolean>(``, registro));
    // return this.http.post(this.apiURL, registro);
  }

  public validarNombre(registro:PreguntaBonus){
    return from(this.api.post<PreguntaBonus, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:PreguntaBonus){
    return from(this.api.put<PreguntaBonus, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}