import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { EmpresaReporte } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class EventoVisitaService {

  private controlador = computed(() => 'eventoVisita');
  private api = new ConsumirApi(this.controlador());

  public obtenerEmpresa(evento:number):Observable<EmpresaReporte>{
    return from(this.api.get<EmpresaReporte>(`BuscarCorreo/${evento}`));
    // return this.http.get<empresaReporteDTO>(`${this.apiURL}/BuscarCorreo/${evento}`);
  }
}
