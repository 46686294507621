import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Directorio, RestablecerSucursal, RestaurarSucursal, Sucursal, SucursalAE, SucursalHorario } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class SucursalService {

  private controlador = computed(() => 'sucursal');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos/${idEmpresa}`);
  }

  public obtenerActivos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerHorarios(idEmpresa:number):Observable<SucursalHorario[]>{
    return from(this.api.get<SucursalHorario[]>(`horariosAngular/${idEmpresa}`));
    // return this.http.get<sucursalHorarioDTO[]>(`${this.apiURL}/horariosAngular/${idEmpresa}`);
  }

  public obtenerSucursalesFalla():Observable<RestaurarSucursal[]>{
    return from(this.api.get<RestaurarSucursal[]>(`SucursalesFalla/${0}`));
    // return this.http.get<restaurarSucursalDTO[]>(`${this.apiURL}/SucursalesFalla/${0}`);
  }

  public obtenerDirectorio(idEmpresa:number):Observable<Directorio[]>{
    return from(this.api.get<Directorio[]>(`ObtenerDirectorio/${idEmpresa}`));
    // return this.http.get<directorioDTO[]>(`${this.apiURL}/ObtenerDirectorio/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, id: number): Observable<Sucursal>{
    return from(this.api.get<Sucursal>(`${idEmpresa}/${id}`));
    // return this.http.get<sucursalDTO>(`${this.apiURL}/${idEmpresa}/${id}`);
  }

  public crear(registro:SucursalAE){
    return from(this.api.post<SucursalAE, boolean>(`AgregarSucursalAE`, registro));
    // return this.http.post(`${this.apiURL}/AgregarSucursalAE`, registro);
  }

  public validarNombre(registro:SucursalAE){
    return from(this.api.post<SucursalAE, boolean>(`validarNombreAE`, registro));
    // return this.http.post(`${this.apiURL}/validarNombreAE`, registro);
  }

  public RestablecerSucursal(registro:RestablecerSucursal){
    return from(this.api.post<RestablecerSucursal, boolean>(`RestablecerSucursal`, registro));
    // return this.http.post(`${this.apiURL}/RestablecerSucursal`, registro);
  }

  public editar(id: number, registro:SucursalAE){
    return from(this.api.put<SucursalAE, boolean>(`updateAngular/${id}`, registro));
    // return this.http.put(`${this.apiURL}/updateAngular/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}