import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, ProblemaEspecifico } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class ProblemaEspecificoService {

  private controlador = computed(() => 'problemaespecifico');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos/${idEmpresa}`);
  }

  public obtenerActivos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idProblema:number, idProblemaEsp: number): Observable<ProblemaEspecifico>{
    return from(this.api.get<ProblemaEspecifico>(`${idProblema}/${idProblemaEsp}`));
    // return this.http.get<problemaEspecificoDTO>(`${this.apiURL}/${idProblema}/${idProblemaEsp}`);
  }

  public crear(registro:ProblemaEspecifico){
    return from(this.api.post<ProblemaEspecifico, boolean>(``, registro));
    // return this.http.post(`${this.apiURL}`, registro);
  }

  public validarNombre(registro:ProblemaEspecifico){
    return from(this.api.post<ProblemaEspecifico, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:ProblemaEspecifico){
    return from(this.api.put<ProblemaEspecifico, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}