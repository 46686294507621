import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class EstadoService {

  private controlador = computed(() => 'estado');
  private api = new ConsumirApi(this.controlador())

  constructor() {}

  public obtenerEstados():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`listaEstados`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/listaEstados`);
  }
}
