import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Categoria } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {

  private controlador = computed(() => 'categoria');
  private api = new ConsumirApi(this.controlador());

  constructor() {}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, id: number): Observable<Categoria>{
    return from(this.api.get<Categoria>(`${idEmpresa}/${id}`));
    // return this.http.get<categoriaDTO>(`${this.apiURL}/${idEmpresa}/${id}`);
  }

  public crear(registro:Categoria){
    return from(this.api.post<Categoria, boolean>(``, registro));
    // return this.http.post(this.apiURL, registro);
  }

  public validarNombre(registro:Categoria){
    return from(this.api.post<Categoria, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:Categoria){
    return from(this.api.put<Categoria, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}