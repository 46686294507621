import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { CalidadEvento, EstandarCalidad } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class CalidadEventoService {

  private controlador = computed(() => 'calidad');
  private api = new ConsumirApi(this.controlador());

  constructor() {}

  public obtenerEstandaresCalidad(): Observable<EstandarCalidad[]>{
    return from(this.api.get<EstandarCalidad[]>(`ObtenerEstandaresCalidad`));
    // return this.http.get<estandarCalidadDTO[]>(`${this.apiURL}/ObtenerEstandaresCalidad`);
  }

  public obtenerEventoUsuario(usuario:string): Observable<number>{
    return from(this.api.get<number>(`ObtenerEventoUsuario/${usuario}`));
    // return this.http.get<number>(`${this.apiURL}/ObtenerEventoUsuario/${usuario}`);
  }

  public obtenerUsuarioEvento(id:number): Observable<string>{
    return from(this.api.getString(`ObtenerUsuarioEvento/${id}`));
    // var headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    // return this.http.get<string>(`${this.apiURL}/ObtenerUsuarioEvento/${id}`,  {
    //   headers,
    //   responseType: 'text' as 'json'
    // });
  }

  public registrarCalidad(objeto:CalidadEvento){
    return from(this.api.post<CalidadEvento, boolean>(`RegistrarCalidad`, objeto));
    // return this.http.post(`${this.apiURL}/RegistrarCalidad`,objeto);
  }
}
