import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, EstandarCalidad } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class EstandarCalidadService {

  private controlador = computed(() => 'estandarcalidad');
  private api = new ConsumirApi(this.controlador());

  constructor() {}

  public obtenerTodos():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(``));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}`);
  }

  public obtenerPorId(id: number): Observable<EstandarCalidad>{
    return from(this.api.get<EstandarCalidad>(`${id}`));
    // return this.http.get<estandarCalidadDTO>(`${this.apiURL}/${id}`);
  }
  
  public crear(registro:EstandarCalidad){
    return from(this.api.post<EstandarCalidad,boolean>(``, registro));
    // return this.http.post(this.apiURL, registro);
  }

  public validarNombre(registro:EstandarCalidad){
    return from(this.api.post<EstandarCalidad, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:EstandarCalidad){
    return from(this.api.put<EstandarCalidad, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}
