
import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Ponderacion, Visita } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class VisitaService {

  private controlador = computed(() => 'visita');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos/${idEmpresa}`);
  }

  public obtenerActivos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, ivVisita: number): Observable<Visita>{
    return from(this.api.get<Visita>(`versionAngular/${idEmpresa}/${ivVisita}`));
    // return this.http.get<visitaDTO>(`${this.apiURL}/versionAngular/${idEmpresa}/${ivVisita}`);
  }

  public obtenerPonderacion(ivVisita: number): Observable<Ponderacion[]>{
    return from(this.api.get<Ponderacion[]>(`ponderacion/${ivVisita}`));
    // return this.http.get<ponderacionDTO[]>(`${this.apiURL}/ponderacion/${ivVisita}`);
  }

  public crear(registro:Visita){
    return from(this.api.post<Visita, boolean>(`versionAngular`, registro));
    // return this.http.post(`${this.apiURL}/versionAngular`, registro);
  }

  public validarNombre(registro:Visita){
    return from(this.api.post<Visita, boolean>(`validarNombreAngular`, registro));
    // return this.http.post(`${this.apiURL}/validarNombreAngular`, registro);
  }

  public editar(id: number, registro:Visita){
    return from(this.api.put<Visita,boolean>(`versionAngular/${id}`, registro));
    // return this.http.put(`${this.apiURL}/versionAngular/${id}`, registro);
  }

  public editarPonderacion(id: number, registro:Ponderacion[]){
    return from(this.api.put<Ponderacion[], boolean>(`ponderacion/${id}`, registro));
    // return this.http.put(`${this.apiURL}/ponderacion/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}
