import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { Actividad, ActividadAE, BasicObjectSMI } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class ActividadService {
  
  private controlador = computed(() => 'actividad');
  private api = new ConsumirApi(this.controlador());

  constructor() {}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, id: number): Observable<Actividad>{
    return from(this.api.get<Actividad>(`${idEmpresa}/${id}`));
    // return this.http.get<actividadDTO>(`${this.apiURL}/${idEmpresa}/${id}`);
  }

  public crear(registro:ActividadAE){
    return from(this.api.post<ActividadAE,boolean>(`versionAngular`, registro));
    // return this.http.post(`${this.apiURL}/versionAngular`, registro);
  }

  public validarNombre(registro:ActividadAE){
    return from(this.api.post<ActividadAE,boolean>(`versionAngular/validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/versionAngular/validarNombre`, registro);
  }

  public editar(id: number, registro:ActividadAE){
    return from(this.api.put<ActividadAE,boolean>(`versionAngular/${id}`, registro));
    // return this.http.put(`${this.apiURL}/versionAngular/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}