import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { ConsumirApi } from '@/utils/consumirApi';

@Injectable({
  providedIn: 'root'
})
export class AsignacionService {

  private controlador = computed(() => 'asignacionautomatica');
  private api = new ConsumirApi(this.controlador());

  constructor() {}

  public EjecutarAsignacion():Observable<boolean>{
    return from(this.api.get<boolean>(`EjecutarAsignacion`));
    // return this.http.get<boolean>(`${this.apiURL}/EjecutarAsignacion`);
  }
}
