import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Pregunta } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class PreguntaService {

  private controlador = computed(() => 'pregunta');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idPolitica:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos/${idPolitica}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos/${idPolitica}`);
  }

  public obtenerActivos(idPolitica:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idPolitica}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idPolitica}`);
  }

  public obtenerPreguntaPoliticas(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`preguntasPoliticas/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/preguntasPoliticas/${idEmpresa}`);
  }

  public obtenerPorId(idPolitica:number, idPregunta: number): Observable<Pregunta>{
    return from(this.api.get<Pregunta>(`${idPolitica}/${idPregunta}`));
    // return this.http.get<preguntaDTO>(`${this.apiURL}/${idPolitica}/${idPregunta}`);
  }

  public crear(registro:Pregunta){
    return from(this.api.post<Pregunta, boolean>(``,registro));
    // return this.http.post(this.apiURL, registro);
  }

  public validarNombre(registro:Pregunta){
    return from(this.api.post<Pregunta, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:Pregunta){
    return from(this.api.put<Pregunta, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}
