import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Supervisor } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class SupervisorService {
  
  private controlador = computed(() => 'supervisor');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, idsupervisor: number): Observable<Supervisor>{
    return from(this.api.get<Supervisor>(`${idEmpresa}/${idsupervisor}`));
    // return this.http.get<supervisorDTO>(`${this.apiURL}/${idEmpresa}/${idsupervisor}`);
  }

  public crear(registro:Supervisor){
    return from(this.api.post<Supervisor, boolean>(``, registro));
    // return this.http.post(this.apiURL, registro);
  }

  public validarNombre(registro:Supervisor){
    return from(this.api.post<Supervisor, boolean>(`validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:Supervisor){
    return from(this.api.put<Supervisor, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}