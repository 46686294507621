import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, FiltroTicket, Ticket } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  private controlador = computed(() => 'ticket');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerMarcas(idTipo:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`ObtenerMarcas/${idTipo}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/ObtenerMarcas/${idTipo}`);
  }

  public obtenerSucursales(idTipo:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`ObtenerSucursales/${idTipo}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/ObtenerSucursales/${idTipo}`);
  }

  public obtenerSucursalesComex(idTipo:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`ObtenerSucursalesComex/${idTipo}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/ObtenerSucursalesComex/${idTipo}`);
  }

  public obtenerTicket(idTicket:number):Observable<Ticket>{
    return from(this.api.get<Ticket>(`${idTicket}`));
    // return this.http.get<ticketDTO>(`${this.apiURL}/${idTicket}`);
  }

  public buscar(objeto:FiltroTicket){
    return from(this.api.post<FiltroTicket, BasicObjectSMI[]>(`buscarAngular`, objeto));
    // return this.http.post<coleccionDTO[]>(`${this.apiURL}/buscarAngular`,objeto);
  }

  public crear(objeto:Ticket){
    return from(this.api.post<Ticket, boolean>(``, objeto));
    // return this.http.post(`${this.apiURL}`,objeto);
  }
}
