import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Nota } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class NotaService {

  private controlador = computed(() => 'nota');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, idsupervisor: number): Observable<Nota>{
    return from(this.api.get<Nota>(`${idEmpresa}/${idsupervisor}`));
    // return this.http.get<notaDTO>(`${this.apiURL}/${idEmpresa}/${idsupervisor}`);
  }

  public crear(registro:Nota){
    return from(this.api.post<Nota, boolean>(``, registro));
    // return this.http.post(this.apiURL, registro);
  }

  public validarNombre(registro:Nota){
    return from(this.api.post<Nota, boolean>(`validarNota`, registro));
    // return this.http.post(`${this.apiURL}/validarNota`, registro);
  }

  public editar(id: number, registro:Nota){
    return from(this.api.put<Nota, boolean>(`${id}`, registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}