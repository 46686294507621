import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Empresa, EmpresaReporte } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  private controlador = computed(() => 'empresa');
  private api = new ConsumirApi(this.controlador())

  constructor() {}

  public obtenerTodos():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos`));
    // return this.http.get<coleccionDTO[]>(this.apiURL + '/todos');
  }

  public obtenerActivos():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(``));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}`);
  }

  public obtenerEmpresasHabit():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`obtenerEmpresasHabit`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}`);
  }

  public obtenerPorUsuario(username:string):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`obtenerEmpresasUsuario/${username}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/obtenerEmpresasUsuario/${username}`);
  }

  public obtenerPorId(id: number): Observable<Empresa>{
    return from(this.api.get<Empresa>(`ObtenerEmpresaEditar/${id}`));
    // return this.http.get<empresaDTO>(`${this.apiURL}/ObtenerEmpresaEditar/${id}`);
  }

  public crear(empresa:Empresa){
    return from(this.api.post<Empresa, boolean>(``, empresa));
    // return this.http.post(this.apiURL,empresa);
  }

  public validarNombre(empresa:Empresa){
    return from(this.api.post<Empresa, boolean>(`validarNombre`, empresa));
    // return this.http.post<boolean>(`${this.apiURL}/validarNombre`,empresa);
  }

  public editar(id: number, empresa:Empresa){
    return from(this.api.put<Empresa, boolean>(`${id}`, empresa));
    // return this.http.put(`${this.apiURL}/${id}`,empresa);
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }

  public obtenerPorUsuarioReport(username:string):Observable<EmpresaReporte[]>{
    return from(this.api.get<EmpresaReporte[]>(`empresausuarios/${username}`));
    // return this.http.get<empresaReporteDTO[]>(`${this.apiURL}/empresausuarios/${username}`);
  }

  public obtenerEmpresaPorUsuarioReport(username:string):Observable<EmpresaReporte>{
    return from(this.api.get<EmpresaReporte>(`empresausuario/${username}`));
    // return this.http.get<empresaReporteDTO>(`${this.apiURL}/empresausuario/${username}`);
  }
}
