import { Injectable, computed } from '@angular/core';
import { BasicObjectSMI, Lider, Transcript } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LiderService {

  private controlador = computed(() => 'lider');
  private api = new ConsumirApi(this.controlador());

  constructor(){}


  public obtenerTodos():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>('todos'));
  }

  public obtenerActivos():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(''));
  }

  public obtenerPorId(id:number): Observable<Lider>{
    return from(this.api.get<Lider>(`${id}`));
  }

  public crear(registro:Lider){
    return from(this.api.post<Lider, boolean>(``, registro));
  }

  public validarNombre(registro:Lider){
    return from(this.api.post<Lider, boolean>(`validarNombre`, registro));
  }

  public editar(id: number, registro:Lider){
    return from(this.api.put<Lider, boolean>(`${id}`, registro));
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
  }

  public obtenerCorreosLider(idLider:number){
    return from(this.api.get<string[]>(`ObtenerCorreos/${idLider}`));
  }
}
