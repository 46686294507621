import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, TareaProgramada } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class TareaProgramadaService {

  private controlador = computed(() => 'tareaprogramada');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos/${idEmpresa}`);
  }

  public obtenerActivos(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/${idEmpresa}`);
  }

  public obtenerPorId(idEmpresa:number, idTarea: number): Observable<TareaProgramada>{
    return from(this.api.get<TareaProgramada>(`VersionMejorada/${idEmpresa}/${idTarea}`));
    // return this.http.get<tareaProgramadaDTO>(`${this.apiURL}/VersionMejorada/${idEmpresa}/${idTarea}`);
  }

  public crear(registro:TareaProgramada){
    return from(this.api.post<TareaProgramada, boolean>(`VersionMejorada`, registro));
    // return this.http.post(`${this.apiURL}/VersionMejorada`, registro);
  }

  public validarNombre(registro:TareaProgramada){
    return from(this.api.post<TareaProgramada, boolean>(`VersionMejorada/validarNombre`, registro));
    // return this.http.post(`${this.apiURL}/VersionMejorada/validarNombre`, registro);
  }

  public editar(id: number, registro:TareaProgramada){
    // return this.http.put(`${this.apiURL}/VersionMejorada/${id}`, registro);
    return from(this.api.put<TareaProgramada, boolean>(`VersionMejorada/${id}`, registro));
  }

  public borrar(id:number){
    return from(this.api.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}