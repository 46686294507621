import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class ConfirmarEnvioService {

  private controlador = computed(() => 'confirmarEnvio');
  private api = new ConsumirApi(this.controlador());

  constructor() {}

  public obtenerEventosPendientes(idEmpresa:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`EventosPendientes/${idEmpresa}`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/EventosPendientes/${idEmpresa}`);
  }

  public confirmarTodo(idEmpresa:number){
    return from(this.api.get<boolean>(`ConfirmarTodo/${idEmpresa}`));
    // return this.http.get(`${this.apiURL}/ConfirmarTodo/${idEmpresa}`);
  }

  public ignorarReporte(idEvento:number){
    return from(this.api.get<boolean>(`IgnorarReporte/${idEvento}`));
    // return this.http.get(`${this.apiURL}/IgnorarReporte/${idEvento}`);
  }

  public confirmarReporte(idEvento:number){
    return from(this.api.get<boolean>(`ConfirmarReporte/${idEvento}`));
    // return this.http.get(`${this.apiURL}/ConfirmarReporte/${idEvento}`);
  }
}
