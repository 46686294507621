
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { from } from 'rxjs';

import { CredencialUsuario, RespuestaAutenticacion } from '@interfaces/index';

@Injectable({
  providedIn: 'root'
})
export class SeguridadService {

  constructor() { }

  apiURL = environment.apiURL + '/cuentas';
  private readonly llaveToken ='token';
  private readonly llaveExpiracion = 'token-expiracion';
  private readonly campoRol = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

  estaLogueado():boolean{

    const token = localStorage.getItem(this.llaveToken);

    if(!token){
      return false;
    }

    const expiracion:any = localStorage.getItem(this.llaveExpiracion);
    const expiracionFecha = new Date(expiracion);
    if(expiracionFecha <= new Date()){
      this.logout();
      return false;
    }
    return true;
  }

  logout(){
    localStorage.setItem('dark','false');
    localStorage.removeItem(this.llaveToken);
    localStorage.removeItem(this.llaveExpiracion);
    localStorage.removeItem('cliente');
    window.location.reload();
  }

  obtenerRol(): string{
    return this.obtnerCampoJWT(this.campoRol);
  }

  obtenerCliente(): string{
    return this.obtnerCampoJWT('cliente');
  }

  obtenerNombreCompleto(): string{
    return this.obtnerCampoJWT('mombreFull');
  }

  obtenerUsuario(): string{
    return this.obtnerCampoJWT('unique_name');
  }

  obtnerCampoJWT(campo: string): string{
    const token = localStorage.getItem(this.llaveToken);
    if(!token){return '';}
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    var dataToken = JSON.parse(jsonPayload);
    return dataToken[campo];
  }

  login(credenciales: CredencialUsuario){

    const login = async(credenciales: CredencialUsuario) => {

      const resp = await fetch(this.apiURL + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credenciales)
      });
  
      if(!resp.ok) throw new Error('No se pudo realizar la traduccion');
  
      const data = await resp.json() as RespuestaAutenticacion;
  
      return data;
    }
    
    return from(login(credenciales));
  }

  guardarToken(respuestaAutenticacion: RespuestaAutenticacion){
    localStorage.setItem('dark', 'false');
    localStorage.setItem(this.llaveToken, respuestaAutenticacion.token);
    localStorage.setItem(this.llaveExpiracion,respuestaAutenticacion.expiracion.toString());
    localStorage.setItem("cliente", this.obtenerCliente());
  }

  obtenerToken(): any{
    return localStorage.getItem(this.llaveToken);
  }
}
