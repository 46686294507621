import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, Segmento } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class SegmentoService {

  private controlador = computed(() => 'segmento');
  private consumirApi = new ConsumirApi(this.controlador())

  constructor() {}

  public obtenerTodos():Observable<BasicObjectSMI[]>{
    return from(this.consumirApi.get<BasicObjectSMI[]>(`todos`));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}/todos`);
  }

  public obtenerActivos():Observable<BasicObjectSMI[]>{
    return from(this.consumirApi.get<BasicObjectSMI[]>(``));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}`);
  }

  public obtenerPorId(id: number): Observable<Segmento>{
    return from(this.consumirApi.get<Segmento>(`${id}`));
    // return this.http.get<segmentoDTO>(`${this.apiURL}/${id}`);
  }

  public crear(registro:Segmento){
    return from(this.consumirApi.post<Segmento, boolean>(``,registro));
    // return this.http.post(this.apiURL,registro);
  }

  public validarNombre(registro:Segmento){
    return from(this.consumirApi.post<Segmento, boolean>(`validarNombre`,registro));
    // return this.http.post(`${this.apiURL}/validarNombre`, registro);
  }

  public editar(id: number, registro:Segmento){
    return from(this.consumirApi.put<Segmento, boolean>(`${id}`,registro));
    // return this.http.put(`${this.apiURL}/${id}`, registro);
  }

  public borrar(id:number){
    return from(this.consumirApi.delete(`${id}`));
    // return this.http.delete(`${this.apiURL}/${id}`);
  }
}
