import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { CriterioHistorial, Historial, TareasDecisiones } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class ConsultaService {

  private controlador = computed(() => 'consulta');
  private api = new ConsumirApi(this.controlador());

  constructor() {}

  public obtenerListadoCorreos(correo:string):Observable<TareasDecisiones[]>{
    return from(this.api.get<TareasDecisiones[]>(`BuscarCorreoNuevaVersion/${correo}`));
    // return this.http.get<tareasDecisionesDTO[]>(`${this.apiURL}/BuscarCorreo/${correo}`);
  }

  public obtenerHistorial(criterio:CriterioHistorial){
    return from(this.api.post<CriterioHistorial, Historial[]>(`Historial`,criterio));
    // return this.http.post<historialDTO[]>(`${this.apiURL}/Historial`, criterio);
  }
}
