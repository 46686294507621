import {environment} from "environments/environment";
import {computed, inject, signal} from '@angular/core';

import {SeguridadService} from "@services/index";
import Swal from 'sweetalert2';

export class ConsumirApi {

    private apiURL = signal(`${environment.apiURL}`);
    private seguridadService = inject(SeguridadService);
    private token = computed(() => this.seguridadService.obtenerToken());

    constructor(controlador : string) {
        var cli = this
            .seguridadService
            .obtenerCliente();
        this
            .apiURL
            .update(link => `${link}/${controlador}/${cli}`);
    }

    async get<T>(link : string) {
        try {
            let linkFull = link == ''
                ? `${this.apiURL()}`
                : `${this.apiURL()}/${link}`;

                // console.log(linkFull);

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${this.token()}`);

            const resp = await fetch(linkFull, {
                method: 'GET',
                headers: myHeaders
            });

            if (!resp.ok) 
                throw new Error('No se pudo realizar la transaccion');
            
            const data = await resp.json()as T;
            return data;
        } catch (error : any) {
            // Swal.fire(error.name + ': "' + error.message + '"', '', 'error');
            throw new Error('No se pudo realizar la transaccion');
        }
    }

    async getString(link : string) {
        try {
            let linkFull = link == ''
                ? `${this.apiURL()}`
                : `${this.apiURL()}/${link}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            myHeaders.append("Authorization", `Bearer ${this.token()}`);

            const resp = await fetch(linkFull, {
                method: 'GET',
                headers: myHeaders
            });

            if (!resp.ok) 
                throw new Error('No se pudo realizar la transaccion');
            
            const data = await resp.text();

            return data;
        } catch (error : any) {
            // Swal.fire(error.name + ': "' + error.message + '"', '', 'error');
            throw new Error('No se pudo realizar la transaccion');
        }

    }

    async getArchivo<T>(link : string) {
        try {
            let linkFull = link == ''
                ? `${this.apiURL()}`
                : `${this.apiURL()}/${link}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${this.token()}`);

            const resp = await fetch(linkFull, {
                method: 'GET',
                headers: myHeaders
            });

            if (!resp.ok) 
                throw new Error('No se pudo realizar la transaccion');
            
            const data = await resp.blob();
            return data;
        } catch (error : any) {
            // Swal.fire(error.name + ': "' + error.message + '"', '', 'error');
            throw new Error('No se pudo realizar la transaccion');
        }

    }

    async post<T, R>(link : string, objectBody : T) {
        try {
            let linkFull = link == ''
                ? `${this.apiURL()}`
                : `${this.apiURL()}/${link}`;

            // console.log(linkFull); 
            // console.log(JSON.stringify(objectBody));
            //  console.log(this.token());

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${this.token()}`);

            const resp = await fetch(linkFull, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(objectBody)
            });

            if (!resp.ok) 
                throw new Error('No se pudo realizar la transaccion');
            
            const data = await resp.json()as R;
            return data;

        } catch (error : any) {
            // Swal.fire(error.name + ': "' + error.message + '"', '', 'error');
            throw new Error('No se pudo realizar la transaccion');
        }
    }

    async postArchivo<T>(link : string, objectBody : T) {
        try {
            let linkFull = link == ''
                ? `${this.apiURL()}`
                : `${this.apiURL()}/${link}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${this.token()}`);

            const resp = await fetch(linkFull, {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(objectBody)
            });

            if (!resp.ok) 
                throw new Error('No se pudo realizar la transaccion');
            
            return await resp.blob();

        } catch (error : any) {
            // Swal.fire(error.name + ': "' + error.message + '"', '', 'error');
            throw new Error('No se pudo realizar la transaccion');
        }
    }

    async put<T, R>(link : string, objectBody : T) {

        try {
            let linkFull = link == ''
                ? `${this.apiURL()}`
                : `${this.apiURL()}/${link}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${this.token()}`);

            const resp = await fetch(linkFull, {
                method: 'PUT',
                headers: myHeaders,
                body: JSON.stringify(objectBody)
            });

            if (!resp.ok) 
                throw new Error('No se pudo realizar la transaccion');
            const data = await resp.json()as R;
            return data;
        } catch (error : any) {
            // Swal.fire(error.name + ': "' + error.message + '"', '', 'error');
            throw new Error('No se pudo realizar la transaccion');
        }

    }

    async delete(link : string) {

        try {
            let linkFull = link == ''
                ? `${this.apiURL()}`
                : `${this.apiURL()}/${link}`;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${this.token()}`);

            const resp = await fetch(linkFull, {
                method: 'DELETE',
                headers: myHeaders
            });

            if (!resp.ok) 
                throw new Error('No se pudo realizar la transaccion');
            return resp.ok;
        } catch (error : any) {
            // Swal.fire(error.name + ': "' + error.message + '"', '', 'error');
            throw new Error('No se pudo realizar la transaccion');
        }
    }
}
