import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, CriterioReporte, FiltroReporte } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  private controlador = computed(() => 'reportepdf');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerListadoPorCriterio(objeto:FiltroReporte){
    return from(this.api.post<FiltroReporte, BasicObjectSMI[] >(`ObtenerListadoPorCriterio`, objeto));
    // return this.http.post<coleccionDTO[]>(`${this.apiURL}/ObtenerListadoPorCriterio`,objeto);
  }

  public obtenerListadoVisitasPorCriterio(objeto:FiltroReporte){
    return from(this.api.post<FiltroReporte, BasicObjectSMI[] >(`obtenerListadoVisitasPorCriterio`, objeto));
    // return this.http.post<coleccionDTO[]>(`${this.apiURL}/obtenerListadoVisitasPorCriterio`,objeto);
  }

  public generarReporte(objeto:CriterioReporte){
    return from(this.api.postArchivo<CriterioReporte >(`GenerarReporte`, objeto));
    // return this.http.post(`${this.apiURL}/GenerarReporte`,objeto,{ responseType: 'blob' });
  }

  public generarReporteEvento(id:number){
    return from(this.api.getArchivo(`GenerarReporteEvento/${id}`));
    // return this.http.get(`${this.apiURL}/GenerarReporteEvento/${id}`,{ responseType: 'blob' });
  }
}
