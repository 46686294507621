import * as Actions from './actions';
import {UiAction} from './actions';
import initialState, {UiState} from './state';

export function uiReducer(state : UiState = initialState, action : UiAction) {

    let cliente = action.payload || state.cliente;

    let navbarVariant = '';
    let sidebarSkin = '';
    cliente = localStorage.getItem('cliente');
    let darkmode =  false;

    try {
        darkmode = (localStorage.getItem('dark') == 'false' ? false : true) ?? false;
    } catch (error) {
        
    }
    switch (action.type) {
        case Actions.TOGGLE_SIDEBAR_MENU:
            return {
                ...state,
                cliente: cliente,
                menuSidebarCollapsed: !state.menuSidebarCollapsed
            };
        case Actions.TOGGLE_CONTROL_SIDEBAR:
            return {
                ...state,
                cliente: cliente,
                controlSidebarCollapsed: !state.controlSidebarCollapsed
            };
        case Actions.TOGGLE_DARK_MODE:
            return {
                ...state,
                cliente: cliente,
                darkMode: !state.darkMode
            };
        case Actions.SET_THEME_CLIENT:

            if (cliente == 'shopology') {
                navbarVariant = 'navbar-success navbar-dark';
                sidebarSkin = 'sidebar-dark-success';
            } else if(cliente == 'steren'){
                navbarVariant = 'navbar-cyan navbar-dark';
                sidebarSkin = 'sidebar-dark-lightblue';
            }
            else{
                navbarVariant = 'navbar-dark';
                sidebarSkin = 'sidebar-dark-success';
            }
            return {
                ...state,
                cliente: cliente,
                navbarVariant: navbarVariant,
                sidebarSkin: sidebarSkin,
                darkMode: darkmode
            };
        default:

        if (cliente == 'shopology') {
            navbarVariant = 'navbar-success navbar-dark';
            sidebarSkin = 'sidebar-dark-success';
        } else if(cliente == 'steren'){
            navbarVariant = 'navbar-cyan navbar-dark';
            sidebarSkin = 'sidebar-dark-lightblue';
        }
        else{
            navbarVariant = 'navbar-dark';
            sidebarSkin = 'sidebar-dark-success';
        }
            return {
                ...state,
                cliente: cliente,
                navbarVariant: navbarVariant,
                sidebarSkin: sidebarSkin,
                darkMode: false
            };
    }

}

// export type componentUsingColor = 'navbarVariant'|'sidebarSkin'; function
// seleccionColor(tipo:componentUsingColor): string{     try{         if(cliente
// == 'shopology'){             switch(tipo){                 case
// 'navbarVariant':                     return 'navbar-success navbar-dark';
// case 'sidebarSkin':                     return 'sidebar-dark-success';
// default:                     return '';             }         }         else{
// switch(tipo){                 case 'navbarVariant': return 'navbar-cyan
// navbar-dark';                 case 'sidebarSkin': return
// 'sidebar-dark-lightblue';                 default: return '';             }
// }     }     catch (error){ console.log(error);         switch(tipo){
// case 'navbarVariant': return 'navbar-success navbar-dark';             case
// 'sidebarSkin': return 'sidebar-dark-success';             default:
// return '';         }     } }